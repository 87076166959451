import React, { useState, useEffect } from "react";
import "./App.css";
import {
  Button,
  Grid,
  Container,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

const Image = styled("img")({
  width: "100%",
  height: "200px",
  objectFit: "cover",
  border: "2px solid #000", // Add this line
});

const DialogImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "contain",
});

function App() {
  const [images, setImages] = useState([]);
  const [view, setView] = useState("images"); // Add this line
  const [tab, setTab] = useState("gallery"); // Add this line
  const [selectedImage, setSelectedImage] = useState(null);

  const backend_url = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    if (view === "images") {
      // Add this line
      fetch(`${backend_url}/api/images.js`)
        .then((response) => {
          console.log("backend_url\n", backend_url);
          console.log("response\n", response);
          return response.json();
        })
        .then((data) => setImages(data));
    } // Add this line
  }, [view]); // Add 'view' to the dependency array

  const handleUploadClick = () => {
    setView("upload");
    setTab("upload");
  };

  const handleGalleryClick = () => {
    // Add this function
    setTab("gallery");
    setView("images");
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await fetch(`${backend_url}/api/upload.js`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Upload failed");
      }

      alert("Upload successful");
      setView("images");
    } catch (error) {
      console.error("Error:", error);
      alert("Upload failed");
    }
  };

  return (
    <Container spacing={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <Typography variant="h4">Main Menu</Typography>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Button variant="contained" onClick={handleGalleryClick}>
                相册
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleUploadClick}>
                上传图片
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={9} style={{ marginTop: "20px" }}>
          {view === "upload" && (
            <div>
              <input type="file" onChange={handleFileUpload} />
            </div>
          )}
          {tab === "gallery" && (
            <Grid container spacing={3}>
              {images.map((image, index) => (
                <Grid item xs={12} sm={4} key={image.id}>
                  <Image
                    src={image.src}
                    alt={image.alt}
                    onClick={() => setSelectedImage(image)}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Dialog open={!!selectedImage} onClose={() => setSelectedImage(null)}>
        <DialogContent>
          <DialogImage src={selectedImage?.src} alt={selectedImage?.alt} />
          <Typography variant="body1">{selectedImage?.alt}</Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontStyle: "italic" }}
          >
            {selectedImage?.description}
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            {selectedImage?.createdAt}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedImage(null)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default App;
